
import { toFeatures, toGeoJSONFeature } from '../../services/openlayers/OlFeatureService';

import { Feature, Map, View } from 'ol';

import Select, { SelectEvent } from 'ol/interaction/Select';
import Snap from 'ol/interaction/Snap';
import Modify from 'ol/interaction/Modify';
import Draw from 'ol/interaction/Draw';

import VectorLayer from 'ol/layer/Vector';

import VectorSource from 'ol/source/Vector';
import ClusterSource from 'ol/source/Cluster';
import { toLonLat } from 'ol/proj';
import { getCenter, buffer } from 'ol/extent';
import Geometry from 'ol/geom/Geometry';

import ScaleLine from 'ol/control/ScaleLine';

import { LogManager } from 'aurelia-framework';
import { subscribe } from '../../util/decorators/subscribe';
import EventEmitter from 'events';
import { debounced } from '../../util/decorators/debounced';
import { bindable } from 'aurelia-framework';
import factory from '../../components/ol-map/factory/olMapFactory';
import { defaultStyle } from '../../components/projects/data/defaultMap';
import defaults from 'lodash.defaults';
import VectorImageLayer from 'ol/layer/VectorImage';

const log = LogManager.getLogger('dfp:MapAdaptor');

export type LayerUpdateMode = 'append' | 'replace';

export interface ILayerParams {
    /**
     * Layer ID to fetch or create in existing map
     */
    layerId: string;
    /**
     * Set to the id field for the object. Default is generally `id`
     */
    idField?: string;
    /**
     * Set to the geometry field for the object. Default is generally `geom`
     */
    geomField?: string;
    /**
     * Set to true to create a new layer if it doesn't exist
     */
    createLayer?: boolean;
    /**
     * Options to pass to layer creation/update
     */
    layerOptions?: Record<string, any>;
    /**
     * Set to true to force update existing layers
     */
    updateLayer?: boolean;
}

export interface ILayerUpdateParams extends ILayerParams {
    create?: Record<string, any>[];
    update?: Record<string, any>[];
    remove?: Record<string, any>[];
    /**
     * Set to true to remove all features prior to updating
     */
    clear?: boolean;
}

export interface FeatureEditResult {
    /**
     * Geometry of feature in geojson object form
     */
    geometry: Record<string, any>;
    /**
     * Openlayers feature item
     */
    feature: Feature<Geometry>;
}

export interface SelectItem {
    feature: Feature<Geometry>;
    properties: Record<string, any>,
    layer: VectorLayer<VectorSource<Geometry>>;
    layerId: string;
}

/**
 * This gets passed as event data when the user selects features,
 * it is emitted using the 'select' event, or 'deselect' event
 */
export interface SelectResult {
    /**
     * Selected or deselected features
     */
    target: SelectItem[];
    type: 'select' | 'deselect';
}

/**
 * This gets passed as event data when
 * the extent changes. It gets emitted
 * using the 'extentchange'
 */
export interface ExtentChangeResult {
    target: View;
    /**
     * 2 coordinate extent in lat/long coordinates
     */
    bbox: number[];
}

export interface IEventMapChange {
    map: Map;
}

/**
 * Stateful map class for storing an "active" map that
 * can be used to update, query, and dispatch events
 *
 */
@subscribe({
    events: [
        { eventEmitter: 'selectInteraction', event: 'select', fn: '_onMapFeatureSelect' },
        { eventEmitter: 'selectInteraction', event: 'deselect', fn: '_onMapFeatureSelect' },
    ],
    attached: '_mapAttached',
    detached: '_mapDetached',
    off: 'un',
})
@subscribe({
    events: [
        { eventEmitter: 'drawInteraction', event: 'drawend', fn: '_onFeatureDrawEnd' },
    ],
    attached: '_drawAttached',
    detached: '_drawDetached',
    off: 'un',
})

export class MapAdaptor extends EventEmitter {
    constructor() {
        super();

        this.selectInteraction = this.selectInteraction || new Select({
            multi: true,
            hitTolerance: 10,
        });
        this.scaleControl = this.scaleControl || new ScaleLine({
            bar: true,
            text: true,
            minWidth: 125,
        });
    }

    // static members

    /**
     * Fired when the map is attached/created
     * @event mapattached
     */
    static EVENT_MAP_ATTACHED = 'mapattached'
    /**
     * Fired whenever the current map is detached/removed
     * @event mapdetached
     */
    static EVENT_MAP_DETACHED = 'mapdetached'
    /**
     * Fired when a feature is selected
     * @event select
     */
    static EVENT_SELECT = 'select';
    /**
     * Fired when a feature is de-selected
     * @event select
     */
    static EVENT_DESELECT = 'deselect';
    /**
     * Fired whenever the extent changes
     * @event extentchange
     */
    static EVENT_EXTENT_CHANGE = 'extentchange';

    // public members
    @bindable selectedZoomItem: Record<string, any>;
    layerCreationSequence = 1;
    selectInteraction: Select;
    drawInteraction: Draw;
    snapInteraction: Snap;
    scaleControl: ScaleLine;
    view: View;
    isEditing = false;

    /**
     * Setter for the map. Update this property whenever the map changes.
     */
    set map(map) {
        this._mapDetached();
        this._map = map;

        log.debug('map set', this._map);
        if (this._map) {
            this.view = this._map.getView();
            this._mapAttached();
        }

    }

    /**
     * Returns the currently set map
     */
    get map() {
        return this._map;
    }

    /**
     * Queries for a feature on the vector layer and returns it.
     * @param item item to query for features
     * @param layerId layer ID to look up
     * @param idField property to query features by
     * @returns feature if found
     */
    public async getFeature(item: Record<string, any>, layerId: string, idField = 'id'): Promise<Feature<Geometry>> {
        if (!item) {
            log.warn('missing item');
            return;
        }

        this.selectedZoomItem = item;

        const source = await this.getVectorLayerSource({ layerId });
        if (!source) {
            return;
        }
        const feature = source.getFeatures().find((feature) => {

            // handle clustered features
            if (feature.get('features')) {
                return !!feature.get('features')
                    .find((feature) => feature.getProperties()[idField] === item[idField]);
            }
            return feature.getProperties()[idField] === item[idField];
        });

        if (!feature) {
            log.warn(`feature ${idField}:${item[idField]} was not found`);
        }
        return feature;
    }

    /**
     * Create and add a new vector layer to the map
     *
     * @param opt Layer options
     * @returns Vector Layer
     */
    async createVectorLayer(opt: Record<string, any>): Promise<VectorLayer<any>> {
        const layerOptions = {
            type: 'ol/layer/VectorImage',
            id: `layer-${this.layerCreationSequence++}`,
            style: defaultStyle,
            ...opt,
            source: {
                type: 'ol/source/Vector',
                features: [],
                ...opt.source,
            },
        };
        log.debug(`creating layer with defaults: ${layerOptions.id}`, layerOptions);

        return factory.create(layerOptions).then((layer) => {
            this._map?.addLayer(layer);
            return layer;
        });
    }

    async updateVectorLayerOptions(opt: Record<string, any>, layerId: string) {

        const layer = await this.getVectorLayer({ layerId });
        if (!layer) {
            return;
        }

        if (layer.get('dfpReady')) {
            log.info(`updateVectorLayer: layer is already updated: ${layerId}`);
            return;
        }

        log.info(`updateVectorLayer: updating layer  ${layerId}`);

        for (const key in opt) {
            if (typeof opt[key] === 'object') {
                opt[key] = await this.createOlObject({
                    dfpReady: true,
                    ...opt[key],
                    ...layer.get(key).getProperties?.(),
                });
            }

            layer.set(key, opt[key]);
        }
    }

    createOlObject(opt: Record<string, any>) {
        return factory.create(opt);
    }

    /**
     * Update an existing vector layer with new data. Pass in plain objects and they
     * will be converted to Features automatically.
     *
     * @param items Array of items to update in map. These should be plain objects
     * @param layerId layer ID to lookup and update. This should already be in the map
     * @param mode Update mode. Can either append to existing features or replace existing features. Default is `replace`
     * @param geomField Field in `items` to use to map features. Should be an object or string of GeoJSON.
     */
    public async updateVectorLayer(
        items: Record<string, any>[],
        layerId: string,
        mode: LayerUpdateMode = 'replace', geomField = 'geom',
        createWithDefaultsIfNotFound?: boolean, idField = 'id',
    ): Promise<void> {
        log.warn('updateVectorLayer deprecated, use updateLayer instead');
        const params: ILayerUpdateParams = {
            create: items,
            clear: createWithDefaultsIfNotFound || false,
            layerId,
            createLayer: createWithDefaultsIfNotFound,
            idField,
            geomField,
        };
        this.updateLayer(params);
    }

    public async updateLayer(params: ILayerUpdateParams): Promise<VectorLayer<any>> {
        if (!this.map) {
            return;
        }
        defaults(params, {
            idField: 'id',
            geomField: 'geom',
        } as ILayerUpdateParams);

        let source = await this.getVectorLayerSource(params);

        if (!source) {
            log.warn(`layer was not found or created ${params.layerId}`, params);
            return;
        }

        // duck type for looking for cluster feature layers
        if ((source as ClusterSource).getSource) {
            source = (source as ClusterSource).getSource();
        }

        // reset layer if necessary
        if (params.clear) {
            source.clear();
        }

        // apply updates by finding existing featuers and updating their geometry
        if (params.update) {
            const existing = await Promise.all(params.update
                .map((i) => this.getFeature(i, params.layerId, 'id'))
                .filter((f) => !!f));
            const updated = await this.createFeatures(params.update, params.geomField);
            existing.forEach((f, idx) => {
                if (!f) {
                    return;
                }
                f.setGeometry(updated[idx]?.getGeometry());
                f.setProperties({
                    ...f.getProperties(),
                    ...params.update[idx],
                });
            });
        }

        // create new features
        if (params.create) {
            source.addFeatures(this.createFeatures(params.create, params.geomField));
        }

        // remove features
        if (params.remove) {
            params.remove.forEach(async (item) => {
                const featureToRemove = await this.getFeature(item, params.layerId, params.idField);
                source.removeFeature(featureToRemove);
            });
        }

        return this.getVectorLayer(params);
    }

    public async getVectorLayer(params: ILayerParams): Promise<VectorLayer<VectorSource<Geometry>>> {
        let layer = (this._map?.getLayers().getArray()
            .find((l) => l.get('id') === params.layerId) as VectorLayer<any>);

        const layerOptions = {
            id: params.layerId,
            ...params.layerOptions,
            dfpReady: true,
        };

        if (!layer) {
            if (params.createLayer) {
                layer = await this.createVectorLayer(layerOptions);
            } else {
                log.warn(`getVectorLayer: ${params.layerId} was not found`, this._map?.getLayers().getArray());
            }
        } else {
            if (params.updateLayer) {
                await this.updateVectorLayerOptions(layerOptions, params.layerId);
            }
        }

        return layer;
    }

    /**
     * Gets a layers source. Mostly used for internal calls to update layers.
     * @param layerId layer ID to lookup and update. This should already be in the map
     * @returns The layer's source
     */
    public async getVectorLayerSource(params?: ILayerParams): Promise<VectorSource<Geometry>> {

        const layer = await this.getVectorLayer(params);
        if (!layer) {
            return;
        }
        const source = layer.getSource();

        if (!source) {
            log.warn(`getVectorLayerSource: ${params.layerId} was not found`, this._map?.getLayers().getArray());
            return;
        }

        return source;
    }

    /**
     * Generates features from plain objects with GeoJSON in them.
     *
     * @param rows Array of records to generate features from
     * @param geomField Field name in `rows` to get GeoJSON geometry from
     * @returns Array of features
     */
    public createFeatures(rows: Record<string, any>[], geomField: string): Feature<Geometry>[] {
        rows = rows
            .map((p) => {
                // if we have valid GeoJSON already, return it
                if (p.geometry && p.properties && p.id) {
                    return p;
                }
                let geom;
                try {
                    geom = typeof p[geomField] === 'string' ?
                        JSON.parse(p[geomField]) :
                        p[geomField];
                } catch (e) {
                    log.warn(e);
                    geom = null;
                }
                return {
                    type: 'Feature',
                    geometry: geom,
                    properties: p,
                };
            })
            .filter((row) => !!row[geomField] || !!row.geometry);
        return toFeatures(rows);
    }

    /**
     * Zoom the map location to a given feature.
     *
     * @param feature The map feature to zoom to
     */
    @debounced(250)
    public zoom(feature: Feature<Geometry>): unknown {
        if (!feature) {
            log.warn('feature was not found');
            return;
        }
        if (!this._map) {
            log.warn('map was not found');
            return;
        }

        log.debug('zoom start', feature);

        // handle clustered features
        if (feature.get('features')) {
            const features = feature.get('features');

            features.forEach((element) => {
                if (element.getProperties().id === this.selectedZoomItem.id) {

                    const view = this._map.getView();
                    const geom = element.getGeometry();
                    const extent = buffer(geom.getExtent(), 100);
                    const center = getCenter(extent);
                    const resolution = view.getResolutionForExtent(extent);

                    view.animate({
                        center: center,
                        resolution,
                    });
                }
            });

        } else {
            const view = this._map.getView();
            const geom = feature.getGeometry();
            const extent = buffer(geom.getExtent(), 100);
            const center = getCenter(extent);
            const resolution = view.getResolutionForExtent(extent);

            view.animate({
                center: center,
                resolution,
            });
        }

    }

    /**
     * Lets the user draw a new geometry on the map and
     * adds it to a layer.
     *
     * If in Edit mode, the user will not be able to select
     *
     * @param layerId Layer ID to create a new geometry on
     * @returns Promise that resolves to a new geometry
     */
    public async create(layerId: string): Promise<FeatureEditResult> {
        this.isEditing = true;
        this.selectInteraction.setActive(false);
        // cancel existing drawings
        this.cancel();

        if (!this._map) {
            log.warn('map was not found');
            return Promise.reject(new Error('Map is not initialized'));
        }

        const source = await this.getVectorLayerSource({ layerId });
        this.drawInteraction = new Draw({
            source,
            type: 'Polygon',
        });
        this.snapInteraction = new Snap({ source });

        return new Promise<FeatureEditResult>((resolve, reject) => {
            this._cancelEdit = () => {
                this.selectInteraction.setActive(true);
                this.isEditing = false;
                this._drawDetached();
                reject(new Error('Edits were cancelled'));
            };

            this._finishEdit = (feature: Feature<Geometry>) => {
                this.selectInteraction.setActive(true);
                if (feature?.getGeometry().getType() !== 'Polygon') {
                    const drawing = this.drawInteraction.getOverlay().getSource().getFeatures().map((feature) => {

                        return feature.getGeometry().getCoordinates();
                    });
                    if (drawing.length > 1) {
                        this.drawInteraction.finishDrawing();
                    } else {
                        reject(new Error('Please add at least 3 points'));
                    }
                    this.isEditing = false;
                    return;
                }
                const json = toGeoJSONFeature(feature);
                resolve({ geometry: json.geometry, feature: feature });
                this.isEditing = false;
            };
            this._drawAttached();
        });
    }
    /**
     * Allows the user to edit a given feature and returns the
     * updated geometry.
     *
     * @param item Plain object to lookup related feature
     * @param layerId Layer ID to look up related feature
     * @returns Promise resolved when edits are complete.
     */
    public async edit(item: Record<string, any>, layerId: string): Promise<FeatureEditResult> {
        this.isEditing = true;
        // stop any existing edits
        this.cancel();

        const feature = await this.getFeature(item, layerId);
        if (!feature) {
            return Promise.reject({message: 'Could not find feature'});
        }
        const originalFeature = feature.clone();
        const modify = new Modify({
            features: this.selectInteraction.getFeatures(),
        });
        this.map.addInteraction(modify);
        this.zoom(feature);
        this.selectInteraction.getFeatures().push(feature);

        const source = await this.getVectorLayerSource({ layerId });
        this.snapInteraction = new Snap({ source });
        this._map.addInteraction(this.snapInteraction);

        const onSelect = (evt: SelectEvent) => {
            if (!evt.deselected.length) {
                return;
            }

            this._finishEdit(evt.deselected[0]);
        };

        const promise = new Promise<FeatureEditResult>((resolve, reject) => {
            this._cancelEdit = () => {
                this.isEditing = false;
                // remove existing geometry, then reject, this.map.drawDetached. this.finish() =  would resolve promise. call drawDetached()
                feature.setGeometry(originalFeature.getGeometry());
                reject(new Error('Edits were cancelled'));
            };
            this._finishEdit = (updatedFeature: Feature<Geometry>) => {
                this.selectInteraction.un('select', onSelect);
                feature.setGeometry(updatedFeature.getGeometry());
                const json = toGeoJSONFeature(updatedFeature);
                this.isEditing = false;
                resolve({ geometry: json.geometry, feature: updatedFeature });
            };
            this.selectInteraction.once('select', onSelect);
        }).finally(() => {
            this.selectInteraction.getFeatures().clear();
            this.map.removeInteraction(modify);
            this.isEditing = false;
        });

        return promise;
    }

    /**
     * Finishes existing edits
     */
    finish() {
        if (this._finishEdit) {
            this._finishEdit(this.selectInteraction.getFeatures().getArray()[0]);
        }
        this._drawCompleted();
    }

    /**
     * Cancels existing edits and removes any
     * existing interactions.
     *
     * @returns void
     */
    cancel() {
        if (this._cancelEdit) {
            this._cancelEdit();
        }
        this._drawCompleted();
    }

    getExtent(): ExtentChangeResult {

        const extent = this.view.calculateExtent(this._map.getSize());
        const coords = [
            toLonLat([
                extent[0],
                extent[1],
            ]),
            toLonLat([
                extent[2],
                extent[3],
            ]),
        ];

        const eventData: ExtentChangeResult = {
            target: this.view,
            bbox: [
                ...coords[0],
                ...coords[1],
            ],
        };

        return eventData;
    }

    /**
     * Select objects on the map (if they are present).
     * @param objects Objects to select. Features will be looked up and selected based on the objects
     * @param layerId Layer to use to look up the features from. For instance, if you want to select features
     * from the 'projectFeatures' layer.
     */
    select(objects: Record<string, any>[], layerId: string, idField = 'id') {
        this.clearSelection();
        const selection = this.selectInteraction.getFeatures();
        objects.forEach(async (obj) => {
            const feature = await this.getFeature(obj, layerId, idField);
            if (!feature) {
                log.warn('Feature not found: ', obj);
                return;
            }
            selection.push(feature);
        });
        return selection;
    }

    /**
     * Resets the currently selected features to unselected
     */
    clearSelection() {
        this.selectInteraction.getFeatures().clear();
    }

    // private
    private _map: Map;
    private _viewEventHandlers: any;
    private _viewEventListener;

    private _mapAttached() {

        this._map.addInteraction(this.selectInteraction);
        this._map.addControl(this.scaleControl);

        this._viewEventListener = () => this._publishExtentChange();
        this._viewEventHandlers = this.view.on(
            ['change:center', 'change:resolution', 'change:rotation'],
            this._viewEventListener,
        );

        this.emit(MapAdaptor.EVENT_MAP_ATTACHED, { map: this._map } as IEventMapChange);
    }

    private _mapDetached() {
        if (!this._map) {
            return;
        }

        this.view.un(
            this._viewEventHandlers,
            this._viewEventListener,
        );
        this._map.removeControl(this.scaleControl);
        this.emit(MapAdaptor.EVENT_MAP_DETACHED, { map: this._map } as IEventMapChange);
    }

    // geometry editing
    private _cancelEdit: any;
    private _finishEdit: any;

    private _drawCompleted() {
        this._cancelEdit = null;
        this._finishEdit = null;
        this._drawDetached();
    }

    private _drawAttached() {
        if (!this._map) {
            return;
        }
        this._map.addInteraction(this.snapInteraction);
        this._map.addInteraction(this.drawInteraction);
    }

    private _drawDetached() {
        if (!this._map) {
            return;
        }
        this._map.removeInteraction(this.snapInteraction);
        this._map.removeInteraction(this.drawInteraction);
    }

    private _onFeatureDrawEnd = (evt) => {
        if (!evt.feature) {
            return;
        }

        this._finishEdit(evt.feature);
        this._drawDetached();
    }

    private _onMapFeatureSelect = (evt: SelectEvent) => {
        const deselections = evt.deselected
            .map((i) => this._getSelectItem(i))
            .filter((item) => !!item.layer);

        if (deselections.length) {
            this.emit(MapAdaptor.EVENT_DESELECT, { target: deselections, type: 'deselect' } as SelectResult);
        }

        const selections = evt.selected
            .map((i) => this._getSelectItem(i))
            .filter((item) => !!item.layer);

        if (selections.length) {
            this.emit(MapAdaptor.EVENT_SELECT, { target: selections, type: 'select' } as SelectResult);
        }
    }

    private _getSelectItem(feature: Feature<Geometry>): SelectItem {
        const targetLayer = this.map.getLayers().getArray()
            .filter((layer) => layer instanceof VectorLayer || layer instanceof VectorImageLayer)
            .find((layer: VectorLayer<any>) => layer.getSource().hasFeature(feature)) as VectorLayer<VectorSource<Geometry>>;

        return {
            feature,
            properties: feature.getProperties(),
            layer: targetLayer,
            layerId: targetLayer?.get('id'),
        };
    }

    @debounced(200)
    private _publishExtentChange() {
        const eventData = this.getExtent();
        this.emit('extentchange', eventData);

    }

}
