import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { GenerateKoboURLData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import get from 'lodash.get';
import defaults from 'lodash.defaults';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ActiveProgram } from '../../../services/util/ActiveProgram';

const log = LogManager.getLogger('dfp:kobo');

interface URLResult {
    url: string;
    name?: string;
    detail?: string;
}

@autoinject
export default class GenerateKoboURLTask {
    constructor(
        private ea: EventAggregator,
        private api: DatafiProAPI,
        private program: ActiveProgram,
    ) { }

    async execute(data: GenerateKoboURLData): Promise<{ url: string }> {
        const target = data.target[0] || {};

        const programSurvey = await this.api.programSurveys.get(data.surveyId);
        data.survey = programSurvey;

        const urlResult = await (
            data.edit ?
                this.getEditUrl(target, data) :
                this.getNewSurveyUrl(target, data)
        );

        if (urlResult.detail) {
            throw new Error(urlResult.detail);
        }

        if (data.tab) {
            window.open(urlResult.url);
        }

        //TODO remove data.open
        else if (data.iframe || data.open) {
            if (data.open) {
                log.warn('GenerateKoboUrl `open` is deprecated. Use `iframe` instead');
            }
            // window.location.href = urlResult.url;
            this.ea.publish('iframe', {
                src: urlResult.url,
                title: urlResult.name,
            });
        }
        return urlResult;
    }

    async getEditUrl(target: Record<string, any>, data: GenerateKoboURLData): Promise<URLResult> {

        const id = target[data.targetIdField] || target.id || target._id;
        if (!id) {
            throw new Error('Row is missing unique ID value');
        }

        const surveyId = data.surveyId;

        if (!surveyId) {
            throw new Error('Survey could not be found');
        }

        let urlResult;
        if (data.survey.survey_type === 'dfp-form') {
            urlResult = await this.api.formUrls.create({
                fileId: data.survey.survey_id,
                instanceId: id,
                type: 'edit_url',
            });

        }

        return {
            ...urlResult,
            name: `Edit form: ${target.projectname || ''} (${id})`,
        };
    }

    async getNewSurveyUrl(target: Record<string, any>, data: GenerateKoboURLData): Promise<URLResult> {
        // check program_id matches
        if (this.program?.id !== data.survey?.program_id && data.survey?.program_id) {
            log.warn(`GenerateKoboUrl: Program id ${this.program.id} doesn't not match survey id ${data.survey.program_id}. Reloading program.`);
            await this.program.load(data.survey.program_id);
        }

        const { targetFields, actionFields } = defaults(data, {
            program: this.program,
            targetFields: { id: 'project_id' },
            actionFields: {
                'survey.id': 'survey_id',
                [data.survey?.program_id ? 'survey.program_id' : 'program.id']: 'program_id',
                'auth.user.email': 'email',
                'auth.user.fullname': 'fullname',
                'auth.user.id': 'userId',
                'auth.user.username': 'username',
            },
        } as Partial<GenerateKoboURLData>);
        let baseUrl;
        const programSurvey = await this.api.programSurveys.get(data.surveyId);
        if (programSurvey.survey_type === 'dfp-form') {
            const result = await this.api.formUrls.create({
                fileId: programSurvey.survey_id,
                type: data.urlType || 'single_url',
            });
            baseUrl = result.url;
        }
        if (!baseUrl) {
            throw new Error('Form URL could not be found');
        }
        const surveyUrl = baseUrl + '?' +
            // get fields from app context
            this.getUrlFragment(data, actionFields) +
            this.getUrlFragment(target, targetFields);
        return {
            url: surveyUrl,
            name: `New Form: ${data.target?.[0]?.project_name || 'Blank'}`,
        };
    }

    getUrlFragment(context: any, fields: Record<string, string>) {
        let url = '';
        if (fields) {
            delete fields.__expanded;
            Object.keys(fields).forEach((field) => {
                const fieldName = fields[field];
                const formFieldName = fieldName.indexOf('.') > -1 ?
                    fieldName.split('.')[1] :
                    fieldName;
                const value = get(context, field);
                if (value) {
                    url += `&d[${formFieldName}]=${encodeURIComponent(value)}`;
                } else {
                    log.warn(`Target field ${field} was undefined`);
                }
            });
        }
        return url;
    }
}
